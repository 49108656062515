<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex succesuss">
    <div slot="no-body">
      <div class="justify-center items-center">
        <div class="d-theme-dark-bg" style="border-radius: 15px">
          <p style='margin-right:0in;margin-left:0in;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0in;margin-bottom:8.0pt;line-height:normal;'><strong><span style='font-size:24px;font-family:"Times New Roman","serif";color:#00B050;'>{{$t('AboutUs')}}</span></strong></p>

<p style='margin-right:0in;margin-left:0in;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0in;margin-bottom:8.0pt;line-height:normal;'><span v-nl2br style='font-size:18px;font-family:"Times New Roman","serif";color:#0E3069;'>{{$t('DesAboutUS')}} </span></p>

<p style='margin-right:0in;margin-left:0in;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0in;margin-bottom:8.0pt;line-height:normal;'><strong><span style='font-size:24px;font-family:"Times New Roman","serif";color:#00B050;'>{{$t('OurVision')}}</span></strong></p>
<p style='margin-right:0in;margin-left:0in;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0in;margin-bottom:8.0pt;line-height:normal;'><span style='font-size:18px;font-family:"Times New Roman","serif";color:#0E3069;'>{{$t('DesVision')}}</span></p>
<p style='margin-right:0in;margin-left:0in;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0in;margin-bottom:8.0pt;line-height:normal;'><strong><span style='font-size:24px;font-family:"Times New Roman","serif";color:#00B050;Word-wrap:break-word;'>{{$t('Ourmission')}}</span></strong></p>
<p  style='margin-right:0in;margin-left:0in;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0in;margin-bottom:8.0pt;line-height:normal;'>

  <span v-nl2br style='font-size:18px;font-family:"Times New Roman","serif";color:#0E3069;'>{{$t('DesOurmission')}}</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    Message: {
      type: String,
      default: () => "",
    },
  },

  directives: {
    nl2br: {
      inserted(el) {
        // simplified example,
        // works only for nodes without any child elements
        el.innerHTML = el.textContent.replace(/\n/g, '<br />')
      }
    }
  },
  components: {},
  computed: {
  //   withBrTags: function() {
  //   const doc = this.item.licensedocument.legal.documentText
  //   return doc.replace(/(\\r)*\\n/g, '<br>')
  // }
  },
  created() {},
};
</script>

<style lang="scss">
.succesuss {
  width: 100%;
  margin: auto;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
}
.P-Text {
  color: #004477;
}
// .text-block {
//     white-space: pre; // or pre-line
// }
</style>


